

































































































































































































































































































































































































































































































































































import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import Button from '@/infrastructure/ui/button.vue'

@Component({
    components: {
        Button
    }
})
export default class GDPRDialog extends Vue {
    @VModel() showGDPR!: boolean
    @Prop() attachTo!: string
}
